import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "WHAT ARE NON-QM LOANS?",
      answer: (
        <>
          <p>
            A non-QM loan, or non-qualified mortgage, is one where alternative
            methods are used to verify income. For example, you can provide bank
            statements to a lender or use your assets to show that you have the
            ability to repay a loan.
          </p>
        </>
      ),
    },
    {
      question: "WHAT IS A QUALIFIED MORTGAGE?",
      answer: (
        <>
          <p>
            A qualified mortgage is one that meets the standards outlined in the
            Dodd-Frank Wall Street Reform and Consumer Protection Act
            (regulation that took effect in 2010 following the financial crisis
            of 2007-2010).
          </p>
          <p className="mb-0">
            Qualified mortgages do not permit risky loan features, such as
            interest-only loans, balloon payments, and negative amortization.
          </p>
        </>
      ),
    },
    {
      question: "WHAT ARE THE BENEFITS OF NON-QM LOANS?",
      answer: (
        <>
          <p>
            The biggest benefit is that non-traditional borrowers have an
            opportunity to purchase a home. Borrowers can also show proof of
            income in different ways, such as by providing bank statements. And
            you can even qualify for a non-QM loan just one day after completing
            a bankruptcy or foreclosure.
          </p>
        </>
      ),
    },

    {
      question: "WHO MIGHT QUALIFY FOR A NON-QM LOAN?",
      answer: (
        <>
          <p>They’re designed for the following types of buyers:</p>
          <ul className="list-disc pl-6">
            <li>Self-employed</li>
            <li>Variable income</li>
            <li>Assets, but no income</li>
            <li>Bad credit</li>
            <li>Bankruptcy</li>
            <li>Foreclosure</li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Non-QM Loans | Home Loans & Mortgages | Accel Brokers"
        description="A non-qm loan is designed for non-typical borrowers,for example, those who are self-employed, have blemished or bad credit, or whose income varies. Learn more."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-24 md:pt-6">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Non-QM Loan: For Higher-Risk Buyers</h1>
              <p>
                Do you want to buy a home in California but you don’t fit the
                mold? A non-qualified mortgage (non-QM) is designed for
                non-typical borrowers—for example, those who are self-employed,
                have blemished or bad credit—even bankruptcy, or those whose
                income varies from month to month.
              </p>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/4.0 Non QM Loans/1.0 Hero.jpg"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 md:mb-20">
            <h2>The Benefits of a Non-QM Loan</h2>
          </header>
          <div className="grid gap-x-10 gap-y-16 md:grid-cols-2 lg:gap-x-20">
            <div className="flex space-x-5">
              <div className="number-text-stroke">1</div>
              <div>
                <h3 className="heading-four mb-2">The Opportunity to Buy</h3>
                <p className="mb-0">
                  If you think you can’t qualify for a home loan, think again.
                  Non-qualified mortgages open the door.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">2</div>
              <div>
                <h3 className="heading-four mb-2">Recent Bad Credit OK</h3>
                <p className="mb-0">
                  Did you know you can qualify for a non-QM loan just one day
                  after completing a bankruptcy or foreclosure?
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">3</div>
              <div>
                <h3 className="heading-four mb-2">Lots of Options</h3>
                <p className="mb-0">
                  Get access to non-traditional loan options designed to help
                  even the most challenging buyers purchase a home.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">4</div>
              <div>
                <h3 className="heading-four mb-2">
                  Flexible Income Documentation
                </h3>
                <p className="mb-0">
                  You can show proof of income in a variety of ways, including
                  bank statements, for example.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h2>Who’s Eligible?</h2>
              <p>
                Because there are so many options, the requirements will vary
                depending on the loan program. It’s important to note that
                although non-QM loans are designed to be more lenient, lenders
                are required to make a good-faith effort to show that you have
                the ability to repay a loan.
              </p>

              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/4.0 Non QM Loans/Non QM.jpg"
                loading="lazy"
                width={560}
                className="z-0 rounded-6xl"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-8 md:mb-10">
            <h2>Frequently Asked Questions</h2>
          </header>
          <Accordion
            allowZeroExpanded={true}
            className="border-t border-gray-700/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    uuid={i}
                    className="border-b border-gray-700/20 py-6 md:py-8"
                  >
                    <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                      <AccordionItemState>
                        {(state) => {
                          return (
                            <>
                              <p className="heading-five mb-0">
                                {faq.question}
                              </p>

                              <i
                                className={`far ${
                                  state.expanded
                                    ? "fa-chevron-up"
                                    : "fa-chevron-down"
                                } text-base text-gray-700/50`}
                              ></i>
                            </>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                      {faq.answer}
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Resources />
      <Testimonials />
      <ValueProps />
      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
