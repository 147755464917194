import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const ValueProps = ({ headingLevel, className }) => {
  const data = useStaticQuery(graphql`
    {
      icon1: file(
        relativePath: { eq: "0.0 Repeating Modules/Value Props/Icon 01.svg" }
      ) {
        publicURL
      }
      icon2: file(
        relativePath: { eq: "0.0 Repeating Modules/Value Props/Icon 02.svg" }
      ) {
        publicURL
      }
      icon3: file(
        relativePath: { eq: "0.0 Repeating Modules/Value Props/Icon 03.svg" }
      ) {
        publicURL
      }
    }
  `);

  const HeadingTag = headingLevel || "h2";

  const content = [
    {
      icon: data.icon1.publicURL,
      heading: "YOU GET A TRUSTED PARTNER",
      text: "We develop lifelong relationships with our clients. Because we believe there’s more to the business than simply quoting rates.",
    },
    {
      icon: data.icon2.publicURL,
      heading: "YOU GET SMART ADVICE",
      text: "We’re consultants, not pencil pushers. We think long-term to help you get the most out of your home loan.",
    },
    {
      icon: data.icon3.publicURL,
      heading: "YOU GAIN KNOWLEDGE",
      text: "Learn from the best—a skilled team with over 20 years of experience in the real estate industry.",
    },
  ];

  return (
    <section className={`${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <header className="mb-12 text-center md:mb-16">
          <HeadingTag>Why You Can Count On Us</HeadingTag>
        </header>

        <div className="relative z-10 grid gap-y-10 text-center md:grid-cols-3 md:gap-x-10 lg:gap-x-20">
          {content.map((content, i) => {
            return (
              <div key={i}>
                <img
                  src={content.icon}
                  alt={content.heading}
                  className="mx-auto mb-3"
                />
                <div>
                  <h3 className="heading-four mb-3">{content.heading}</h3>
                  <p className="mb-0 text-sm md:text-base">{content.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ValueProps;
